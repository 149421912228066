<template>
	<div>
		<!-- 轮播图 -->
		<div class="carouesel_box">
			<el-carousel height="460px">
				<el-carousel-item v-for="(item, index) in banner" :key="index"><img style="height:460px; width: 100%;" :src="item.pic" :alt="item.name" /></el-carousel-item>
			</el-carousel>
			<div class="service-box">
				<div>
					<div>
						<i class="el-icon-medal"></i>
						<span>原产地直采正品保障</span>
					</div>
					<div>
						<i class="el-icon-collection-tag"></i>
						<span>成本压缩物美价廉</span>
					</div>
					<div>
						<i class="el-icon-time"></i>
						<span>平均订单时效7.6小时</span>
					</div>
					<div>
						<i class="el-icon-user"></i>
						<span>7*12小时订单全程服务</span>
					</div>
				</div>
			</div>
			<div class="all-num">
				已有
				<span class="big-text">10577</span>
				名分销商入驻
			</div>
		</div>
		<!-- 分类展示区域 -->
		<div class="wrapper">
			<div v-for="(item, index) in category" :key="index">
				<div class="text">
					<div>
						<span class="name">{{ item.title }}</span>
						<span class="line">{{ item.title_lite }}</span>
					</div>
					<router-link :to="{ path: '/goods_list', query: { cid: item.cid, title: item.title } }" class="more">
						更多
						<i class="el-icon-arrow-right"></i>
					</router-link>
				</div>
				<Good-list :good-list="item.productList"></Good-list>
			</div>
		</div>
		<!-- 		<el-dialog title="消费者告知书" :visible.sync="dialogVisible" width="36%" :before-close="handleClose">
			<div class="conter" v-html="articleInfo.content"></div>
			<span slot="footer" class="dialog-footer"><el-button type="primary" @click="dialogVisible = false">确 定</el-button></span>
		</el-dialog> -->
		<iframe
			v-if="mapKey"
			ref="geoPage"
			width="0"
			height="0"
			frameborder="0"
			scrolling="no"
			:src="'https://apis.map.qq.com/tools/geolocation?key=' + mapKey + '&referer=myapp'"
		></iframe>
	</div>
</template>

<script>
import { getHomeData, getProducts, getArticleDetails } from '@api/user';
import GoodList from '@components/GoodList';
export default {
	name: 'Home',
	components: {
		GoodList
	},
	data() {
		return {
			mapKey: '',
			articleInfo: {},
			// dialogVisible: true,
			banner: [],
			category: [],
			where: {
				page: 1,
				limit: 10,
				cid: 0
			}
		};
	},
	mounted: function() {
		let that = this;
		// that.articleDetails();
		getHomeData()
			.then(res => {
				// that.logoUrl = res.data.logoUrl;
				that.$set(that, 'banner', res.data.bannerPc);
				that.$set(that, 'activity', res.data.activity);
				that.$set(that, 'category', res.data.category);
				that.mapKey = res.data.tengxun_map_key;
				if (that.mapKey) that.getWXLocation();
				that.$nextTick(() => {
					that.getProductList();
				});
			})
			.catch(err => {
				that.$dialog.error(err.msg);
			});
	},
	methods: {
		getWXLocation() {
			let that = this;
			window.addEventListener(
				'message',
				function(event) {
					// 接收位置信息
					var loc = event.data;
					if (loc && loc.module == 'geolocation') {
						var adcode = loc.adcode.toString().substring(0, 4) + '00';
						that.regionId = parseFloat(adcode);
						window.sessionStorage.setItem('regionId', that.regionId);
					}
				},
				false
			);
		},
		articleDetails: function() {
			let that = this,
				id = 2;
			getArticleDetails(id).then(res => {
				that.articleInfo = res.data;
				that.add_time = that.articleInfo.add_time.trim().split(' ')[0];
			});
		},
		async getProductList() {
			let that = this;
			for (let i = 0; i < that.category.length; i++) {
				that.where.cid = that.category[i].cid;
				await getProducts(that.where).then(res => {
					that.$set(that.category[i], 'productList', res.data.goods);
				});
			}
		}
	}
};
</script>

<style lang="less" scoped>
.carouesel_box {
	position: relative;
	.all-num {
		position: absolute;
		z-index: 10;
		height: 48px;
		padding: 0 42px;
		background: #fff;
		top: 2px;
		right: calc(50% - 616px);
		line-height: 48px;
		.big-text {
			color: #f60;
			font-size: 20px;
			font-weight: 700;
		}
	}
	.service-box {
		width: 100%;
		height: 42px;
		line-height: 42px;
		color: #fff;
		font-size: 14px;
		background-color: rgba(0, 0, 0, 0.25);
		position: absolute;
		z-index: 10;
		bottom: 0;
		left: 0;
		font-weight: bold;

		> div {
			width: 1232px;
			margin: 0 auto;
			display: flex;
			justify-content: space-around;
			align-items: center;

			i {
				padding-right: 10px;
			}
		}
	}
}
.wrapper {
	.text {
		height: 80px;
		line-height: 80px;
		margin-top: 24px;
		font-family: PingFang SC;
		position: relative;
		display: flex;
		justify-content: space-between;
		.name {
			font-size: 24px;
			font-weight: 700;
			margin-left: 16px;
		}
		.line {
			color: #999;
			font-size: 14px;
			margin-left: 6px;
		}
	}
	.text:before {
		content: '';
		position: absolute;
		margin-top: -12px;
		top: 50%;
		left: 0;
		width: 4px;
		height: 24px;
		line-height: 96px;
		background: #f60;
		opacity: 1;
	}
}
</style>
